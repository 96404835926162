// Foundation by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

// a. Base
// - - - - - - - - - - - - - - - - - - - - - - - - -

// This is the default html and body font-size for the base rem value.
$rem-base: 16px;



@import "foundation/components/global";
@import "foundation/functions"; // Allows the use of rem-calc() or lower-bound() in your settings

// The default font-size is set to 100% of the browser style sheet (usually 16px)
// for compatibility with browser-based text zoom or user-set defaults.

// Since the typical default browser font-size is 16px, that makes the calculation for grid size.
// If you want your base font-size to be different and not have it affect the grid breakpoints,
// set $rem-base to $base-font-size and make sure $base-font-size is a px value.
$base-font-size: 100%;
// The $base-font-size is 100% while $base-line-height is 150%
$base-line-height: 150%;

// We use this to control whether or not CSS classes come through in the gem files.
$include-html-classes: true;
$include-print-styles: true;
$include-html-global-classes: $include-html-classes;


// b. Grid
// - - - - - - - - - - - - - - - - - - - - - - - - -

$include-html-grid-classes: $include-html-classes;
$include-xl-html-grid-classes: false;

$row-width: rem-calc(1140);
$total-columns: 12;

$last-child-float: $opposite-direction;


// c. Global
// - - - - - - - - - - - - - - - - - - - - - - - - -

// We use these as default colors throughout
//$primary-color: #008CBA;
$primary-color: #32588F;
$secondary-color: #e7e7e7;
$alert-color: #f04124;
$success-color: #43AC6A;
$warning-color: #f08a24;
$info-color: #a0d3e8;

// We use these to control various global styles
$body-bg: #FFF;
$body-font-color: $jet;
$body-font-family: $font-family-sans-serif;
$body-font-weight: $font-weight-normal;
$body-font-style: normal;

// We use these to define default font stacks
$font-family-sans-serif: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
$font-family-serif: Georgia, Cambria, "Times New Roman", Times, serif;
$font-family-monospace: Consolas, "Liberation Mono", Courier, monospace;

// We use these to define default font weights
$font-weight-normal: normal;
$font-weight-bold: bold;

// We use these to make sure border radius matches unless we want it different.
$global-radius: 3px;
$global-rounded: 1000px;

// We use these to control inset shadow shiny edges and depressions.
$shiny-edge-size: 0 1px 0;
$shiny-edge-color: rgba($white, .5);
$shiny-edge-active-color: rgba($black, .2);


// d. Media Query Ranges
// - - - - - - - - - - - - - - - - - - - - - - - - -

$small-range: (0em, 40em);
$medium-range: (40.063em, 64em);
$large-range: (64.063em, 90em);
$xlarge-range: (90.063em, 120em);
$xxlarge-range: (120.063em, 99999999em);

$screen: "only screen";

$landscape: "#{$screen} and (orientation: landscape)";
$portrait: "#{$screen} and (orientation: portrait)";

$small-up: $screen;
$small-only: "#{$screen} and (max-width: #{upper-bound($small-range)})";

$medium-up: "#{$screen} and (min-width:#{lower-bound($medium-range)})";
$medium-down: "#{$screen} and (max-width:#{upper-bound($medium-range)})";
$medium-only: "#{$screen} and (min-width:#{lower-bound($medium-range)}) and (max-width:#{upper-bound($medium-range)})";

$large-up: "#{$screen} and (min-width:#{lower-bound($large-range)})";
$large-only: "#{$screen} and (min-width:#{lower-bound($large-range)}) and (max-width:#{upper-bound($large-range)})";

$xlarge-up: "#{$screen} and (min-width:#{lower-bound($xlarge-range)})";
$xlarge-only: "#{$screen} and (min-width:#{lower-bound($xlarge-range)}) and (max-width:#{upper-bound($xlarge-range)})";

$xxlarge-up: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)})";
$xxlarge-only: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)}) and (max-width:#{upper-bound($xxlarge-range)})";

// We use this as cursors values for enabling the option of having custom cursors in the whole site's stylesheet
$cursor-crosshair-value: crosshair;
$cursor-default-value: default;
$cursor-disabled-value: not-allowed;
$cursor-pointer-value: pointer;
$cursor-help-value: help;
$cursor-text-value: text;


// e. Typography
// - - - - - - - - - - - - - - - - - - - - - - - - -

$include-html-type-classes: $include-html-classes;

// We use these to control header font styles
$header-font-family: $body-font-family;
$header-font-weight: $font-weight-normal;
$header-font-style: normal;
$header-font-color: $jet;
$header-line-height: 1.4;
$header-top-margin: .2rem;
$header-bottom-margin: .5rem;

// We use these to control header font sizes
$h1-font-size: rem-calc(44);
$h2-font-size: rem-calc(37);
$h3-font-size: rem-calc(27);
$h4-font-size: rem-calc(23);
$h5-font-size: rem-calc(18);
$h6-font-size: 1rem;

// We use these to control header size reduction on small screens
$h1-font-reduction: rem-calc(10);
$h2-font-reduction: rem-calc(10);
$h3-font-reduction: rem-calc(5);
$h4-font-reduction: rem-calc(5);
$h5-font-reduction: 0;
$h6-font-reduction: 0;

// These control how subheaders are styled.
$subheader-line-height: 1.4;
$subheader-font-color: scale-color($header-font-color, $lightness: 35%);
$subheader-font-weight: $font-weight-normal;
$subheader-top-margin: .2rem;
$subheader-bottom-margin: .5rem;

// A general <small> styling
$small-font-size: 60%;
$small-font-color: scale-color($header-font-color, $lightness: 35%);

// We use these to style paragraphs
$paragraph-font-family: inherit;
$paragraph-font-weight: $font-weight-normal;
$paragraph-font-size: 1rem;
$paragraph-line-height: 1.6;
$paragraph-margin-bottom: rem-calc(20);
$paragraph-aside-font-size: rem-calc(14);
$paragraph-aside-line-height: 1.35;
$paragraph-aside-font-style: italic;
$paragraph-text-rendering: optimizeLegibility;

// We use these to style <code> tags
$code-color: $oil;
$code-font-family: $font-family-monospace;
$code-font-weight: $font-weight-normal;
$code-background-color: scale-color($secondary-color, $lightness: 70%);
$code-border-size: 1px;
$code-border-style: solid;
$code-border-color: scale-color($code-background-color, $lightness: -10%);
$code-padding: rem-calc(2) rem-calc(5) rem-calc(1);

// We use these to style anchors
$anchor-text-decoration: none;
$anchor-text-decoration-hover: none;
$anchor-font-color: $primary-color;
$anchor-font-color-hover: scale-color($anchor-font-color, $lightness: -14%);

// We use these to style the <hr> element
$hr-border-width: 1px;
$hr-border-style: solid;
$hr-border-color: $gainsboro;
$hr-margin: rem-calc(20);

// We use these to style lists
$list-font-family: $paragraph-font-family;
$list-font-size: $paragraph-font-size;
$list-line-height: $paragraph-line-height;
$list-margin-bottom: $paragraph-margin-bottom;
$list-style-position: outside;
$list-side-margin: 1.1rem;
$list-ordered-side-margin: 1.4rem;
$list-side-margin-no-bullet: 0;
$list-nested-margin: rem-calc(20);
$definition-list-header-weight: $font-weight-bold;
$definition-list-header-margin-bottom: .3rem;
$definition-list-margin-bottom: rem-calc(12);

// We use these to style blockquotes
$blockquote-font-color: scale-color($header-font-color, $lightness: 35%);
$blockquote-padding: rem-calc(9 20 0 19);
$blockquote-border: 1px solid $gainsboro;
$blockquote-cite-font-size: rem-calc(13);
$blockquote-cite-font-color: scale-color($header-font-color, $lightness: 23%);
$blockquote-cite-link-color: $blockquote-cite-font-color;


// 03. Block Grid
// - - - - - - - - - - - - - - - - - - - - - - - - -

$include-html-block-grid-classes: $include-html-classes;
$include-xl-html-block-grid-classes: false;

// We use this to control the maximum number of block grid elements per row
$block-grid-elements: 12;
$block-grid-default-spacing: rem-calc(20);

$align-block-grid-to-grid: false;
@if $align-block-grid-to-grid {$block-grid-default-spacing: $column-gutter;}

// Enables media queries for block-grid classes. Set to false if writing semantic HTML.
$block-grid-media-queries: true;


// 11. Forms
// - - - - - - - - - - - - - - - - - - - - - - - - -

// We use this to set the base for lots of form spacing and positioning styles
$form-spacing: rem-calc(16);

// We use these to style the labels in different ways
$form-label-pointer: pointer;
$form-label-font-size: rem-calc(14);
$form-label-font-weight: $font-weight-normal;
$form-label-line-height: 1.5;
$form-label-font-color: scale-color($black, $lightness: 30%);
$form-label-small-transform: capitalize;
$form-label-bottom-margin: 0;
$input-font-family: inherit;
$input-font-color: rgba(0,0,0,0.75);
$input-font-size: rem-calc(14);
$input-bg-color: $white;
$input-focus-bg-color: scale-color($white, $lightness: -2%);
$input-border-color: scale-color($white, $lightness: -20%);
$input-focus-border-color: scale-color($white, $lightness: -40%);
$input-border-style: solid;
$input-border-width: 1px;
$input-border-radius: $global-radius;
$input-disabled-bg: $gainsboro;
$input-disabled-cursor: $cursor-default-value;
$input-box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
$input-include-glowing-effect: true;

// We use these to style the fieldset border and spacing.
$fieldset-border-style: solid;
$fieldset-border-width: 1px;
$fieldset-border-color: $gainsboro;
$fieldset-padding: rem-calc(20);
$fieldset-margin: rem-calc(18 0);

// We use these to style the legends when you use them
$legend-bg: $white;
$legend-font-weight: $font-weight-bold;
$legend-padding: rem-calc(0 3);

// We use these to style the prefix and postfix input elements
$input-prefix-bg: scale-color($white, $lightness: -5%);
$input-prefix-border-color: scale-color($white, $lightness: -20%);
$input-prefix-border-size: 1px;
$input-prefix-border-type: solid;
$input-prefix-overflow: hidden;
$input-prefix-font-color: $oil;
$input-prefix-font-color-alt: $white;

// We use this setting to turn on/off HTML5 number spinners (the up/down arrows)
$input-number-spinners: false;

// We use these to style the error states for inputs and labels
$input-error-message-padding: rem-calc(6 9 9);
$input-error-message-top: -1px;
$input-error-message-font-size: rem-calc(12);
$input-error-message-font-weight: $font-weight-normal;
$input-error-message-font-style: italic;
$input-error-message-font-color: $white;
$input-error-message-bg-color: $alert-color;
$input-error-message-font-color-alt: $oil;

// We use this to style the glowing effect of inputs when focused
$glowing-effect-fade-time: 0.45s;
$glowing-effect-color: $input-focus-border-color;

// Select variables
$select-bg-color: $ghost;
$select-hover-bg-color: scale-color($select-bg-color, $lightness: -3%);


// 30. Tables
// - - - - - - - - - - - - - - - - - - - - - - - - -

// $include-html-table-classes: $include-html-classes;

// These control the background color for the table and even rows
$table-bg: $white;
$table-even-row-bg: $snow;

// These control the table cell border style
$table-border-style: solid;
$table-border-size: 1px;
$table-border-color: $gainsboro;

// These control the table head styles
$table-head-bg: $white-smoke;
$table-head-font-size: rem-calc(14);
$table-head-font-color: $jet;
$table-head-font-weight: $font-weight-bold;
$table-head-padding: rem-calc(8 10 10);

// These control the table foot styles
$table-foot-bg: $table-head-bg;
$table-foot-font-size: $table-head-font-size;
$table-foot-font-color: $table-head-font-color;
$table-foot-font-weight: $table-head-font-weight;
$table-foot-padding: $table-head-padding;

// These control the caption
$table-caption-bg: transparent;
$table-caption-font-color: $table-head-font-color;
$table-caption-font-size: rem-calc(16);
$table-caption-font-weight: bold;

// These control the row padding and font styles
$table-row-padding: rem-calc(9 10);
$table-row-font-size: rem-calc(14);
$table-row-font-color: $jet;
$table-line-height: rem-calc(18);

// These are for controlling the layout, display and margin of tables
$table-layout: auto;
$table-display: table-cell;
$table-margin-bottom: rem-calc(20);


// 36. Visibility Classes
// - - - - - - - - - - - - - - - - - - - - - - - - -

$include-html-visibility-classes: $include-html-classes;
$include-accessibility-classes: true;
$include-table-visibility-classes: true;
$include-legacy-visibility-classes: true;

