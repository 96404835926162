header.desktop {
	padding: 20px 0px 0px 0px;
	background-color: rgba(255, 255, 255, 0.7);
	position: relative;
	z-index: 100;
	
	@include with-touch-nav {
		display: none; 
	}
	
	div.row {
		
		.state {
			padding-top: 45px;
		}
		
		.slogan {

			h1 {
				padding-top: 40px;
				color: #00027b;
				text-transform: uppercase;
				font-family: 'Raleway', sans-serif;
				font-size: 17px;
				font-weight: 700;
				line-height: 20px;
				position: relative;
				padding-left: 70px;

				@media #{$medium-only} {
					padding-top: 0px;
					font-size: 15px;
					line-height: 18px;
				}

				&:before {
					width: 60px;
					height: 40px;
					content: '';
					display: block;
					background-image: url('../images/montana.png');
					background-size: contain;
					background-repeat: no-repeat;
					position: absolute;
					left: 0;
				}
			}
		}
		
		nav {
			padding-top: 5px;
			text-align: right;
			
			ul > li {
				display: inline-block;
				margin: 0px 0px 0px 15px;

				&:first-child {
					margin-left: 0px;
					
					a {
						margin-left: 0px;
						
						&:before {
							content: "\f007";
						}
					}
				}
				
				&:last-child {
										
					a:before {
						content: "\f044";
					}
				}
				
				a {
					margin: 0px 0px 10px 15px;
					font-size: 14px;
					color: $font-blue;
					text-transform: uppercase;
					display: inline-block;
					
					&:before {
						margin: 0px 12px 0px 0px;
						font-family: fontawesome;
						color: $light-red;
					}
				}
			}
		}
	}

	> nav {
		margin-top: -14px;
		background-color: $font-blue;
		text-align: right;

		@media #{$medium-only} {
			margin-top: 0px;
		}

		.menu-primary-container {
			display: inline-block;

			ul {
				display: inline-block;
				
				> li {
					display: inline-block;
					margin: 0px 0px 0px 2px;

					%highlighted {
						background-color: $dark-blue;
					}
					
					&.current-menu-item a,
					&.current_page_item a {
						@extend %highlighted;
					}
					
					&:first-child {
						margin-left: 0px;
					}
					
					> a {
						display: block;
						padding: 0px 14px;
						font-size: 16px;
						line-height: 40px;
						color: #fff;
						@media #{$medium-down} {
							font-size: 14px;
							padding: 0 12px;
						}

						&:hover {
							@extend %highlighted;
						}
					}
		
					> .sub-menu {
						display: none;
						position: absolute;
						text-align: right;
						margin: 0;
						
						> li {
							position: relative;
							display: block;
							z-index: 1000;
							background-color: $light-blue;
							margin: 0 10px 0 0;
							min-width: 200px;
							text-align: left;

							&.current-menu-item a,
							&.current_page_item {
								@extend %highlighted;
								
							}

								&:hover {
									@extend %highlighted;
								}
						}
						ul.sub-menu {
							display: none;
							position: absolute;
							left: 100%;
							top: 0;
							width: auto;
							text-align: left;
							margin: 0;
							padding:0;

							a {
								padding: 0px 14px;
							}
						}
							&:hover .sub-menu {
								display: block;
							}
					}
					&:hover .sub-menu {
						display: block;
					}
				}
			}
		}
		
		a.fa-facebook-square {
			margin-left: 30px;
			font-size: 16px;
			color: #fff;
		}
	}
}

header.mobile {
	$mobile-header-height: 45px; // Height of header
	$nav-toggle-size: 30px; // Size of hamburger
	$mobile-header-padding: #{($mobile-header-height - $nav-toggle-size) / 2};

	display: none;
	padding: $mobile-header-padding;
	width: 100%;
	height: $mobile-header-height;
	background-color: rgba(255, 255, 255, 0.7);
	position: relative;
	z-index: 99;

	@include with-touch-nav {
		display: block;
	}

	// The menu toggle button
	.toggle-off-canvas {
		display: inline-block;
		font-weight: 100;
		font-size: $nav-toggle-size;
		cursor: pointer;
		z-index: 99;
				
		&::before {
			position: relative;
			top: -10px;
			font-family: FontAwesome;
			font-size: $nav-toggle-size;
			width: $nav-toggle-size;
			height: $nav-toggle-size;
			margin-right: 0.5em;
			content: "\f0c9";
		}
	}

	.logo {
		display: inline-block;
		img {
			vertical-align: bottom;
			height: 40px;
		}
	}

	nav {
		text-align: right;
		position: absolute;
		right: 0;
		top: 0;
		padding: 5px 5px 0 0;

		ul > li {
			display: block;
			margin: 0px 0px 0px 15px;

			&:first-child {
				margin-left: 0px;
				
				a {
					margin-left: 0px;
					
					&:before {
						content: "\f007";
					}
				}
			}
			
			&:last-child {
				
				a:before {
					content: "\f044";
				}
			}
			
			a {
				margin: 0px 0px 5px 15px;
				display: inline-block;
				font-size: 14px;
				color: $font-blue;
				text-transform: uppercase;
				
				&:before {
					margin: 0px 12px 0px 0px;
					font-family: fontawesome;
					color: #f11b00;
				}
			}
		}
	}
}

.mobile-off-canvas {
	position: fixed;
	top: 0;
	z-index: 105;
	width: 60vw;
	min-width: 280px;
	max-width: 400px;
	height: 100vh;
	background-color: white;

	transition: left 0.5s ease-in-out;
	left: -100%;

	&.active {
		left: 0;
	}

	header {
		padding: 8px;
		margin-bottom: 16px;

		border-bottom: 1px solid black;
	}


	nav {
		padding: 10px;
		font-size: 20px;
		text-align: left;
		@media #{$small-only} {
			padding: 5px;
		}

		.menu-primary-container {
			display: block;

			ul {
				display: block;
				margin: 0;
				
				> li {
					position: relative;
					display: block;
					padding: 0;
					margin: 0;

					%highlighted {
						background-color: $light-grey;
					}
					
					&.current-menu-item a,
					&.current_page_item a {
						@extend %highlighted;
						color: #fff;
					}
					
					> a {
						position: relative;
						font-size: 16px;
						line-height: 40px;
						color: $dark-blue;
						padding: 10px;

						&:hover {
							@extend %highlighted;
						}
					}
		
					> .sub-menu {
						display: none;
						position: absolute;
						width: auto;
						text-align: left;
						margin: 0;
						padding:0;
						
						> li {
							position: relative;
							left: 0%;
							z-index: 1000;
							background-color: $light-grey;
							min-width: 140px;
							margin: 0;
							padding: 0;


							&.current-menu-item a,
							&.current_page_item {
								@extend %highlighted;
								color: #fff;
								
							}

								&:hover {
									@extend %highlighted;
								}

						a {
							padding: 10px;
						}
						}
						ul.sub-menu {
							display: none;
							position: absolute;
							left: 0%;
							top: 100%;
							width: auto;
							text-align: left;
							margin: 0;
							padding:0;
							border-top: solid 1px #fff;

							a {
								padding: 10px;
							}

							ul.sub-menu {
								display: none;
								position: absolute;
								left: 0%;
								top: 100%;
								width: auto;
								text-align: left;
								margin: 0;
								padding:0;
								border-top: solid 1px #fff;

								a {
									padding: 10px;
								}
							}
								&:hover .sub-menu {
									display: block;
								}
						}
							&:hover .sub-menu {
								display: block;
							}


					}
					&:hover .sub-menu {
						display: inline-block;
					}
				}
			}
		}
	}
}