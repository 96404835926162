body.login {
	background-color: #134282;

	div#login h1 a {
		background-image: url(../images/logo.png);
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
		width: 300px;
		height: 100px;
	}

	 #backtoblog a, #nav a, h1 a {
		color: #fff; 

		&:hover {
			color: darken( #fff, 20%);
		}
	}


	#loginform {
		margin: 25px 0px 25px 0px;
		padding: 10px 10px;
		background-color: #fff;
		

		p {
			margin: 10px 15px 20px;

			label {
				font-size: 12px;
				font-weight: 100;
				color: $light-blue;
				
			}

			input,
			textarea {
				margin: 0px;
				padding: 5px 5px;
				font-size: 16px;
				color: #333;
				background-color: #f5f5fc;
				-webkit-box-shadow:inset 0 2px 2px 0 rgba(0,0,0,0.4);
				box-shadow:inset 0 2px 2px 0 rgba(0,0,0,0.4);
				border-radius: 3px;
				
				&:focus {
					background-color: #fff;
					-webkit-box-shadow: 0 0 15px 0 rgba(0,2,123,0.15);
					box-shadow: 0 0 15px 0 rgba(0,2,123,0.15);
				}
				
				&.medium {
					width: 275px;
				}
			}
		}
		
		input[type="submit"] {

			display: inline-block;
			margin: 0px;
			margin-top: 5px;
			padding: 0px 30px;
			background-color: $light-blue;
			border-style: none;
			font-size: 14px;
			color: #fff;
			
			&:hover {
				background-color: $dark-blue;
			}
		}
	}	
}