main.front {
	padding-top: 0px;

	.slideshow {
		margin-top: -300px;
			@media #{$medium-down} {
				margin-top: 0;
			}

		.slides {
			position: relative;
			height: 900px;
			width: 100%;
			z-index: 0;

			@media #{$medium-down} {
				height: 300px;
			}

			.slide {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 900px;
				content: '';
				background-repeat: no-repeat;
				background-position: center center;
				background-size: cover;
				background-color: white;
				opacity: 0;
				transition: opacity 0.5s ease-in-out;

				&.previous {
					opacity: 0;
				}

				&.current {
					opacity: 1;
				}

				&.next {
					opacity: 0;
				}

				@media #{$medium-down} {
					height: 300px;
				}

				.slide-info {
					height: 125px;
					width: 685px;
					position: absolute;
					top: 50%;
					right: 0%;
					@media #{$medium-down} {
						height: 80px;
						width: 386px;
					}
					@media #{$small-only} {
						width: 80%;
						margin-right: 10px;
						top: 20%;
					}
				}

				h2 {
					background-color: rgba(241, 27, 0, 0.5);
					color: #fff;
					font-size: 36px;
					padding: 20px 80px 30px;
					font-weight: 400;
					font-family: $font-body;
					position: relative;


					@media #{$medium-down} {
						font-size: 24px;
						padding: 10px 15px;
						line-height: 1.25;
					}
					@media #{$small-only} {
						font-size: 12px;
						line-height: 18px;
						padding: 8px;
					}
				}

				a.button {
					position: relative;
					background-color: $light-blue;
					border: solid 1px #fff;
					font-size: 14px;
					padding: 20px 40px;
					left: 50%;
					top: -23px;
					color: #fff;
					@media #{$medium-down} {
						top: -15px;
						padding: 15px 30px;
					}
					@media #{$small-only} {
						left: 0;
						top: 60px;
					}

					&:after {
						font-family: fontawesome;
						content: "\f178";
						margin: 0 0 0 8px;
						font-size: 14px;
						color: #fff;
					}

					&:hover {
						background-color: $dark-blue;
					}

				}
			}
		}
	}

	#industry {
		margin: 0px auto;
		padding: 15px 0px;
		background-color: #fff;
		width: 100%;

		.industry-logos {
			margin: 0;
			padding: 8px 0;
			text-align: center;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: center;

			a {
				margin: 10px 30px;

				@media #{$small-only} {
					margin: 0;
					width: 50%;
					padding: 10px;
				}

				img {
					width: 80px;
					height: auto;

				}
			}
		}
	}

	#tokens {
		height: 200px;
		border-bottom: solid 10px #00027b;
		background-image: url(../images/bg-tokens.jpg);
		background-repeat: repeat;
		text-align: center;
		@media #{$small-only} {
			height: 160px;
		}

		.fa {
			color: $dark-blue;
			font-size: 60px;
			line-height: 50px;
			margin: 20px;

			@media #{$small-only} {
				margin: 20px 0px;
				font-size: 40px;
			}

			&:hover {
				color: lighten($dark-blue, 20%);
			}

		}

		ul {
			display: flex;

			li {
				display: inline-flex;
				justify-content: space-around;
				margin: 0 auto;
				width: 25%;
				height: 190px;
				border-right: solid 1px #fff;

				@media #{$small-only} {
					height: 160px;
				}

				.token-label {
					@media #{$small-only} {
						display: none;
					}
				}

				&:before {
					display: none;
				}

				&:last-child {
					border: none;
				}

				&.current {
					background-color: $light-blue;
					position: relative;

					.fa {
						color: #6061bf;
					}

					.token-label {
						color: #fff;
					}
				}

				a {
					display: flex;
					align-items: center;
					text-align: center;
					justify-content: center;
					height: 100%;
					width: 100%;
				}
			}
		}
		.token-tabs {
			@media #{$small-only} {
				margin: 0;
			}
		}
	}

	#teasers {
		margin-top: 60px;
		padding: 0 20px;

		@media #{$small-only} {
			padding: 0 5px;
			margin-top: 20px;
		}

		h1 {
			font-weight: bold;
		}

		.teaser-event {
			display: none;

			&.active {
				display: block;
			}
		}

		.meta {
			margin-bottom: 40px;
			padding: 0px 0px 10px 0px;
			display: flex;


			@media #{$small-only} {
				margin-bottom: 50px;
				margin: 0px 0px 40px;
			}

			li {

				&:before {
					display: none;
				}

			}

			.date {
				font-size: 34px;
				line-height: 34px;
				color: #8c8c8c;
				height: auto;
				text-transform: uppercase;
				width: 25%;
				margin-right: 45px;
				padding: 30px 20px 20px 20px;
				text-align: center;


				background-color: #EEEEEE;

				@media #{$medium-down} {
					font-size: 30px;
					margin-left: 8px;
				}

				@media #{$small-only} {
					padding: 10px;
					margin: 0;
					margin-right:20px;
					font-size: 18px;
					line-height: 15px;

				}

				strong {
					display: block;
					font-size: 65px;
					line-height: 65px;
					color: #424242;
					width: 100%;

					@media #{$medium-down} {
						font-size: 45px;
					}
				}

				span {
					display: block;
					font-size: 31px;
					line-height: 31px;
					color: #8c8c8c;
					width: 100%;

					@media #{$small-only} {
						font-size: 18px;
					}
				}
			}

			.event-info {
				width: 75%;
				padding-top: 5px;
			}

			h3 {
				margin: 0px 0px 20px 0px;
				font-size: 24px;
				font-weight: 700;
				color: #00027b;

				@media #{$medium-down} {
					font-size: 18px;
					margin: 0px 0px 8px 0px;
				}

				@media #{$small-only} {
					line-height: 1.2em;
				}

				a {
					color: #00027b;
				}
			}

			.location {
				font-size: 18px;
				color: #424242;
					@media #{$medium-down} {
						font-size: 14px;
					}

				&:before {
					margin: 0px 10px 0px 0px;
					font-family: fontawesome;
					content: "\f041";
					color: #bdbdbd;
				}
			}

		}

		.event-excerpt {
			padding: 10px 0px 0px 0px;
			color: #919191;
			line-height: 24px;
		}

		.event-link {

			a {
				&:hover {
					color: lighten($dark-blue, 20%);
				}
			}

			@media #{$small-only} {
				min-height: 104px;
			}
		}

		.teaser-event {
			.read-more {
				display: none;
			}

			.post-info {
				a {
					color: $light-red;
					&:after {
						margin: 0px 0px 0px 8px;
						font-family: fontawesome;
						content: "\f178";
						color: $light-red;
					}
				}
				@media #{$small-only} {
					margin-bottom: 30px;
				}
			}
		}

		.learn-more {
			display: block;
			font-size: 14px;
			line-height: 60px;
			color: #fff;
			background-color: #f11b00;
			text-align: center;
			padding: 0 35px;
			bottom: 0;
			max-width: 250px;

			@media #{$small-only} {
				margin-bottom: 15px;
			}

			&:after {
				margin: 0px 0px 0px 8px;
				font-family: fontawesome;
				content: "\f178";
			}

			&.more {
				margin-top: 40px;
				color: #333;
				background-color: #ddd;

			}
		}

		.more {
			text-align: center;
			display: inline-block;
			width: 100%;
			max-width: 400px;
			font-size: 14px;
			line-height: 60px;
			color: #333;
			background-color: #ddd;


			&:after {
				margin: 0px 0px 0px 8px;
				font-family: fontawesome;
				content: "\f178";
			}
		}

		.resources {
			img {
				float: right;
				width: 500px;
				height: auto;

				@media #{$small-only} {
					float: none;
					margin-bottom: 20px;
				}
				/*
				display: block;
				position: relative;
				left: 35%;
				top: -300px;
				@media #{$small-only} {
					top: 0;
					left: 0;
					width: 315px;
				}
				*/
			}

			ul {
				margin-bottom: 0px;
			}
		}

		.members
			h3 {
				padding-top: 15px;
			}
	}
}
