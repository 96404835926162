#members {
	margin: 0px auto;
	padding: 15px 0px;
	background-color: #fff;
	width: 100%;

	.member-logos {
		margin: 0;
		padding: 8px 0;
		text-align: center;
		justify-content: center;

		h1 {
			display: block;
			text-align: center;
		}

		.logos {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;

			img {
				margin: 10px 30px;
				width: 115px;
				height: auto;
			}
		}
	}
}

#associates {
	margin: 0px auto;
	padding: 30px 0px;
	border-top: solid 1px #00027b;
	background-color: #fff;
	width: 100%;

	.associate-logos {
		margin: 0;
		padding: 8px 0;
		text-align: center;
		justify-content: center;
		overflow: hidden;

		h1 {
			text-align: center;
		}

		.logos {
			position: relative;
			display: block;
			list-style: none;
			margin: 0;
			white-space: nowrap;

			.logo {
				position: relative;
				display: inline-block;
				white-space: nowrap;
				padding: 0.5rem;

				a {
					display: block;
					height: 100px;
					width: 160px;
					background-size: contain;
					background-repeat: no-repeat;
					background-position: center;
				}
			}
		}

	}
}

footer.primary {

	#optin {
		padding: 20px 0px 40px 0px;
		background-color: rgba(0, 0, 0, 0.35);
		color: #fff;
		font-size: 16px;

		.large-7 {
			padding-top: 18px;
		}

		span {
			line-height: 46px;

			&.fa-graduation-cap:before {
				float: left;
				margin: 0px 10px 0px 0px;
				font-size: 40px;
				color: #fffaa4;
			}
		}

		@media #{$medium-down} {
			padding-left: 35px;
		}

		@media #{$small-only} {
			padding-left: 0px;
		}



		.gform_wrapper {
			margin: 0px;

			form {
				margin: 0px;

				.gform_body {
					margin: 0px;

					.gform_fields {
						margin: 0px;

						.gfield {
							float: left;
							clear: none;
							margin: 0px 10px 0px 0px;

							@media #{$small-only} {
								margin-top: 20px;
							}

							label.gfield_label {
								margin: 0px;
								font-size: 12px;
								font-weight: 100;
								color: #c2c2c2;

								&:after {
									margin-left: 8px;
									font-family: fontawesome;
									content: "\f149";
									color: #000;
								}

								span.gfield_required {
									display: none;
								}
							}

							.ginput_container {
								margin: 0px;

								input {
									margin: 5px 0px 0px 0px;
									padding: 0px;
									width: 180px;
									line-height: 35px;
									color: #000;
									background-color: #fff;
									border-radius: 3px;
									border-style: none;
								}
							}
						}
					}
				}

				.gform_footer {
					float: left;
					clear: none;
					margin: 0px;
					padding: 0px;

					button {
						margin: 20px 0px 0px 0px;
						width: 55px;
						text-align: center;
						text-transform: uppercase;
						font-size: 12px;
						line-height: 35px;
						color: #fff;
						background-color: #f11b00;
						border-radius: 3px;
						border-style: none;

						@media #{$small-only} {
							margin-top: 42px;
						}

						&:hover {
							background-color: #cc2a14;
						}

						&:after {
							font-family: fontawesome;
							content: "\f178";
							margin-left: 8px;
							color: #fff;
						}
					}
				}
			}
		}
	}

	#legal {
		padding: 40px 0px;
		color: #fff;
		font-size: 14px;
		line-height: 18px;
		text-align: center;
	}

}
