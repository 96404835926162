main {
	padding: 0px 0px 60px 0px;
	background-color: #fff;
	font-size: 16px;
	line-height: 24px;
	color: $font-grey;
	font-family: $font-body;

	article {
		padding: 0 10px;

		.css-events-list {
			.events-table {
				tr {
					padding: 15px 0;

					td {
						padding: 15px;

						@media #{$small-only} {
							padding: 0px;
						}
					}
				}
			}
		}

		&.has-sidebar {
			display: flex;

			@media (max-width: 960px) {
				flex-direction: column;
			}

			.content,
			.sidebar {
				@media (min-width: 961px) {
					flex-basis: 32rem;
				}
			}

			.content {

			}

			.sidebar {
				flex-shrink: 0;

				margin: 0 0 0 3rem;

				@media (max-width: 960px) {
					margin: 2rem 0 0;
				}
			}

		}
	}

	#banner {
		position: relative;
		margin-top: -300px;
		margin-bottom: 40px;
		padding: 0px 0px 0px 0px;
		height: 475px;
		background-color: rgba(3, 18, 125, 0.5);



		h1 {
			margin: 0px;
			font-size: 48px;
			line-height: 1.2em;
			color: #fff;
			position: absolute;
			padding-left:10px;
			top: 350px;

			@media #{$medium-down} {
				font-size: 40px;
			}
		}

		.post-image {
			width: 100%;
			height: 475px;
		}
		&:before {
			position: absolute;
			content: '';
			background-color: rgba(3, 18, 125, 0.5);
			height: 475px;
			width: 100%;
		}
	}

	h1 {
		margin: 0px 0px 25px 0px;
		font-size: 48px;
		line-height: 52px;
		color: $font-body;
		font-family: $font-body;
		padding-top: 15px;

		@media #{$small-only} {
			font-size: 30px;
			line-height: 36px;
		}
	}

	h2 {
		margin: 0px 0px 20px 0px;
		font-size: 40px;
		line-height: 44px;
		color: $light-blue;
		font-family: $font-body;
	}

	h3 {
		margin: 0px 0px 15px 0px;
		font-size: 30px;
		line-height: 1.2em;
		color: $light-grey;
		font-family: $font-body;
	}

	h4 {
		margin: 0px 0px 15px 0px;
		font-size: 24px;
		line-height: 28px;
		color: $light-grey;
		font-family: $font-body;
	}

	h5 {
		margin: 0px 0px 10px 0px;
		font-size: 21px;
		line-height: 25px;
		color: $light-grey;
		font-family: $font-body;
	}

	h6 {
		margin: 0px 0px 10px 0px;
		font-size: 18px;
		line-height: 22px;
		color: $light-grey;
		font-family: $font-body;
	}

	ul {
		margin: 0px 0px 25px 25px;
		list-style-type: none;

		li {
			margin-left: 14px;

			&:before {
				position: absolute;
				font-family: fontawesome;
				content: '\f178';
				color: $light-red;
				font-size: 24px;
				height: 24px;
				width: 24px;
				line-height: 24px;
				margin:0 5px 0 -40px;
			}
		}

	}

	ol {
		margin: 0px 0px 25px 25px;
		list-style-type: decimal;
	}

	p {
		margin: 0px 0px 25px 0px;
		font-family: $font-body;
		line-height: 1.5;

		a, a:link, a:visited, a:link {
			color: $light-blue;

			&:hover {
				color: $dark-blue;
			}
		}
	}

	a {
		color: $light-blue;

		&:hover {
			color: $dark-blue;
		}
	}

	blockquote {
		position: relative;
		padding: 10px 50px;
		font-size: 26px;
		line-height: 2.2;

		span {
			font-weight: 300;
			color: $light-grey;
		}

		&:before {
			position: absolute;
			font-family: fontawesome;
			content: '\f10d';
			color: $light-red;
			font-size: 70px;
			height: 70px;
			width: 70px;
			line-height: 70px;
			top: 10px;
			left: -45px;
			z-index: 200;
		}

		&:after {
			position: absolute;
			font-family: fontawesome;
			content: '\f10e';
			color: #e9e9e9;
			font-size: 70px;
			height: 70px;
			width: 70px;
			line-height: 70px;
			bottom: 20px;
			right: 45px;
			z-index: 200;
		}
	}

	.alignleft {
		float: left;
		margin: 0px 20px 20px 0px;
	}

	.alignright {
		float: right;
		margin: 0px 0px 20px 20px;
	}

	.aligncenter {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}

	figure {
		margin-bottom: 25px;

		figcaption {
			font-size: 14px;
			line-height: 16px;
			color: #333;
		}
	}

	.events-table {
		width: 100%;

		.event-time {
			width: 33%;
		}

		.event-description {
			width: 33%;
		}

		.event-location {
			width: 33%;
		}

		thead {

			tr {

				th {
					font-weight: 700;
					color: #000;
					text-align: left;
				}
			}
		}

		tbody {

			tr {
				border-bottom: solid 1px #666;

				td {

				}
			}
		}
	}

	.gform_wrapper {
		margin: 0px 0px 25px 0px;
		padding: 0px;
		// max-width: 500px;

		form {
			margin: 0px;
			padding: 0px;

			 .gform_body {
				margin: 0px;
				padding: 0px;

				 ul.gform_fields {
					margin: 0px;
					padding: 0px;

					 li.gfield {
						margin: 0px;
						padding: 0px;

						@media #{$small-only} {
							width: 100%;
						}

						&.gfield_error {
							margin: 0px;
							padding: 0px;
							background: transparent;
							border-style: none;

							.ginput_container {

								input,
								textarea {
									background-color: #FFDFE0;
									border-style: none;
								}

							}

							.validation_message {
								display: none;
							}
						}

						 label.gfield_label {
							margin: 0px 0px 5px 0px;
							padding: 0px;
							font-size: 12px;
							font-weight: 100;
							color: $light-blue;

							&:after {
								font-family: fontawesome;
								content: "\f149";
								margin-left: 8px;
							}

							span.gfield_required {
								display: none;
							}
						 }

						 .ginput_container {
							margin: 0px 0px 15px 0px;
							padding: 0px;

							select {
								border: solid 1px #e9e9e9;
							}

							input,
							textarea {
								margin: 0px;
								padding: 5px 5px;
								font-size: 16px;
								color: #333;
								background-color: #f5f5fc;
								-webkit-box-shadow:inset 0 2px 2px 0 rgba(0,0,0,0.4);
								box-shadow:inset 0 2px 2px 0 rgba(0,0,0,0.4);
								border-radius: 3px;
								max-width: 275px;

								&:focus {
									background-color: #fff;
									-webkit-box-shadow: 0 0 15px 0 rgba(0,2,123,0.15);
									box-shadow: 0 0 15px 0 rgba(0,2,123,0.15);
								}

								&.medium {
									width: 100%;
									max-width: 275px;
								}
							}
						 }
					 }
				 }
			 }

			 .gform_footer {
				margin: 0px;
				padding: 0px;

				button {
					display: inline-block;
					margin: 0px;
					padding: 0px 30px;
					background-color: $light-blue;
					border-style: none;
					font-size: 14px;
					line-height: 44px;
					color: #fff;

					&:hover {
						background-color: $dark-blue;
					}

					&:after {
						font-family: fontawesome;
						content: "\f178";
						margin-left: 8px;
						color: #fff;
					}
				 }
			 }
		}
	}

	.member-list {

		h2 {

			margin-top: 60px;
			width: 100%;
			border-bottom: 1px solid #CCC;
			padding-bottom: 10px;

		}
	}

	h2.form-confirmation {
		padding-top:60px;

	}
}

.category {
	.read-more {
		color: $light-red;
	}
}

form#gform_3 {

	ul#gform_fields_3 {
		display: flex;
		flex-wrap: wrap;

		.gfield {
			width: 33%;
			padding-right: 30px;

			@media #{$small-only} {
				width: 100%;
				padding-right: 0px;
			}
		}

		li.gsection {
			width: 100%;
		}
	}


}



main .em-calendar-wrapper {
	table {
		width: 100%;

		.days-names td {
			padding-bottom: 0;

			background-color: #FAFAFA;

			text-align: center;
			font-weight: 700;
			font-size: 0.9em;
		}

		thead {
			td {
				padding: 0;
				text-align: center;

				background-color: $dark-blue;

				color: #FFF;
				border-color: $dark-blue;

				&.month_name {
					cursor: default;
				}

				.em-calnav {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0.25rem;
					width: 100%;
					height: 100%;
					min-height: 2rem;

					&:hover {
						background-color: $dark-red;
						color: #FFF;
					}
				}
			}
		}

		tbody {
			td {
				min-width: 60px;

				color: $dark-blue;

				&:not(.eventful) {
					padding-bottom: 1.5rem;
				}

				&.eventful {
					padding: 0;
				}

				> a {
					display: block;
					margin: 0 0 0.125rem;
					padding: 0 0.325rem 0.125rem;
					width: 100%;

					font-weight: 700;
					border-bottom: 1px solid #CCC;
					background-color: #F9F9F9;

					&:hover {
						background-color: #EEE;
					}
				}
			}

			.days-names td {
				padding-bottom: 0;

				color: #333;
				background-color: #F6F6F6;
			}
		}


		ul {
			display: block;
			margin: 0;
			padding: 0;

			li {
				display: block;
				margin: 0;
				padding: 0.25rem;

				font-size: 0.8rem;
				line-height: 1.25em;

				// border: 1px solid red;

				&:before {
					display: none;
				}

				a {
					display: block;
					font-weight: 400;
				}
			}
		}
	}
}

.gecko-calendar-wrapper {
	max-width: 100%;
	overflow-x: auto;
}