.page {
	.associate-members {
		display: flex;
		flex-wrap: wrap;
		
		.associate-info {
			display: block;
			width: 280px;
			padding: 20px;
			text-align: center;
			@media #{$medium-down} {
				width: 190px;
				font-size: 12px;
				padding: 5px;
			}
			@media #{$small-only} {
				width: 150px;
				font-size: 12px;
				padding: 5px;
			}

			.member-logo {
				width: 100%;
				height: 150px;
				background-repeat: no-repeat;
				background-position: center center;
				background-size: contain;
				margin: 8px;
			}
			
			h3 {
				font-size: 24px;
				padding-top: 10px;
				@media #{$small-only} {
					font-size: 18px;
				}
			}

			.member-link {
				padding: 10px;
				line-height: 24px;
			}
		}
	}

	.member-list {

		ul {
			border: 1px solid #CCC;
			margin-left: 0;

			li {
				display: flex;
				flex-wrap: wrap;
				margin-left: 0;
				padding: 10px 10px;

				&:nth-child(odd) {
					background-color: #F6F6FC;
				}

				&:before {
					display: none;
				}

				.name.member-column {
					flex-basis: 30%;

					@media #{$small-only} {
						flex-basis: 100%;
					}
				}

				.member-column {
					flex-basis: 20%;

					@media #{$small-only} {
						flex-basis: 100%;
					}
				}


			}

			li.header-row {
				font-weight: bold;
				color: $font-blue;
				border-bottom: 1px solid #CCC;
			}
		}
	}
}

.pdf-lists {
	display: flex;
	justify-content: space-between;
	margin-bottom: 2rem; 
	padding-bottom: 1rem;
	border-bottom: 1px solid #ccc;

	.pdf-col {

		flex-basis: 20%;
		padding-right: 20px;

		&.wide {
			flex-basis: 100%;
		}

		h2 {
			font-size: 1.5em;
		}

		a {
			width: 100%;
			display: inline-block;
			padding-left: 22px;
			text-indent: -22px;
			margin-bottom: 10px;

			&:before {
				content:'\f0f6';
				font-family: fontawesome;
				margin-right: 5px;
			}
		}
	}
}
