body {
	margin: 0px;
	font-family: 'Raleway', sans-serif;
	font-size: 16px;
	font-weight: 100;
	background-color: #949494;
	background-image: url(../images/bg-body.jpg);
	background-repeat: repeat;
}

strong {
	font-weight: 700;
}

a {
	transition: all .2s ease-in-out;
}

// Gecko Modal class
ge-modal {
	display: block;
	position: fixed;

	transition: top 0.5s ease-in-out, visibility 0s linear 0.5s;
	visibility: hidden;
	top: -100%;

	&.active {
		transition-delay: 0s;
		visibility: visible;
		top: 100px;
	}

	.close::before {
		content: "\f00d";
		font-family: FontAwesome;
		position: absolute;
		top: 0;
		right: 0;
		padding: 8px;
		cursor: pointer;
		color: rgba(255, 255, 255, 0.5);
	}
}

// Blackout: used for hiding the site.
#blackout {
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: 100;
	background-color: black;

	transition: opacity 0.5s ease-in-out, visibility 0s linear 0.5s;
	opacity: 0;
	visibility: hidden;

	&.active {
		opacity: 0.7;
		visibility: visible;
		transition-delay: 0s;
	}
}