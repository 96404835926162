.page.gallery {

	.gallery-filter-list {

		li {

			&:before {
				display: none;
			}
		}
	}

	#gallery-list {
		display: block;
		margin: 10px 0;
		padding: 0;
		@include clearfix;

		.list-item {
			display: block;
			width: calc(33.33% - 2rem);
			margin: 1rem;

			a {
				display: block;
				text-align: center;
				width: 100%;
				height: 170px;

				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
			}

			img {
				width: 100%;
				height: auto;
			}

			.caption {
				display: block;
				font-size: 0.9em;
				line-height: 1.1em;
				text-align: center;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}

			&:before {
				display: none;
			}

			@media #{$medium-only} {
				width: calc(50% - 2rem);
			}

			@media #{$small-only} {
				width: 100%;
				margin: 1rem 0;
			}
		}
	}

	h2 {
		font-size: 36px;
	}

	h3 {
		font-size: 16px;
	}

	.filter-by {
		margin: 20px 0 0;
	}

	.gallery-filter-list {
		list-style: none;
		padding: 20px;
		background-color: #e4e4e4;
		margin: 0;
		margin-top: 10px;

		> li {
			display: block;
			padding: 5px 0;
			margin: 0;

			&:not(:last-child) {
				border-bottom: 1px solid $dark-red;
			}

			a {
				position: relative;
				display: block;
				font-size: 1.2em;
				padding: 0 0 0 30px;
				margin: 0;
				color: #7a7473;

				&:after {
					display: block;
					position: absolute;
					left: 2px;
					top: 0;
					font-family: fontawesome;
					content: "\f10c";
					color: #CCC;
					font-size: 18px;
					width: 20px;
				}

				&.active:after {
					content: "\f192";
					color: $light-blue;
				}
			}
		}
	}
}

.single-gallery {
	article {
		display: block;

		.gallery-large {
			width: 90%;
		}
	}
}

.isotope-item {
  z-index: 2;
}

.isotope-hidden.isotope-item {
  pointer-events: none;
  z-index: 1;
}
