// Add site-specific constants here.


//Fonts
$font-body: 'Raleway', sans-serif;

//Colors
$dark-blue: #040c4d;
$light-blue: #00027b;
$font-blue: #03127d;
$light-red: #f11b00;
$dark-red: #cc2a14;
$light-grey: #606060;
$dark-grey: #424242;
$font-grey: #919191;



