.login-form {
	margin: 0 auto;

	#loginform {
		margin: 0px 0px 25px 0px;
		padding: 0px;
		display: inline-flex;
		flex-wrap: wrap;

		p {
			margin: 0 15px;

			@media #{$small-only} {
				margin-bottom: 15px;
			}

			label {
				font-size: 12px;
				font-weight: 100;
				color: $light-blue;
				&:after {
					font-family: fontawesome;
					content: "\f149";
					margin-left: 8px;
				}
			}

			input,
			textarea {
				margin: 0px;
				padding: 5px 5px;
				font-size: 16px;
				color: #333;
				background-color: #f5f5fc;
				-webkit-box-shadow:inset 0 2px 2px 0 rgba(0,0,0,0.4);
				box-shadow:inset 0 2px 2px 0 rgba(0,0,0,0.4);
				border-radius: 3px;
				
				&:focus {
					background-color: #fff;
					-webkit-box-shadow: 0 0 15px 0 rgba(0,2,123,0.15);
					box-shadow: 0 0 15px 0 rgba(0,2,123,0.15);
				}
				
				&.medium {
					width: 275px;
				}
			}
		}
		
		.login-submit input {
			display: inline-block;
			margin: 0px;
			padding: 0px 30px;
			background-color: $light-blue;
			border-style: none;
			font-size: 14px;
			line-height: 44px;
			color: #fff;
			
			&:hover {
				background-color: $dark-blue;
			}
		}
	}
}

h3.not-logged {
	margin-top: 70px;
	text-align: center;
	font-size: 32px;
	color: $dark-red;
}